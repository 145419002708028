<template>
  <default-layout>
    <template v-slot:main>
      <div class=" py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div class="relative max-w-xl mx-auto">
          <div class="text-center">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Firma søg
            </h2>
            <p class="mt-4 text-lg leading-6 text-gray-500">
              Her på CVR Data kan du søge efter lave opslag direkte i CVR registeret.
            </p>
          </div>
          <form @submit.prevent="fieldUpdate" @input="fieldUpdate" class="mt-6 sm:mx-auto sm:max-w-lg sm:flex">
            <div class="min-w-0 flex-1">
              <label for="cta-email" class="sr-only">Firma søg</label>
              <input v-model="search" id="cta-email" type="email" class="block shadow-md w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600" placeholder="Søg efter en virksomhed her" />
            </div>
            <!-- <div class="mt-4 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                class="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-500 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
              >
                Søg
              </button>
            </div> -->
          </form>
        </div>

        <div v-if="results != null && results.hits.length > 0" class="bg-white mt-16 mx-auto max-w-5xl shadow overflow-hidden sm:rounded-md">
          <ul role="list" class="divide-y divide-gray-200">
            <li v-for="result in results.hits" :key="result">
              <router-link
                :to="{
                  name: 'company-overview',
                  params: { cvr: result.cvrNummer }
                }"
                class="block hover:bg-gray-200 focus:ring-indigo-600"
              >
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="text-sm font-medium text-indigo-600 truncate">
                      {{ result.virksomhedMetadata.nyesteNavn.navn }}
                    </p>
                    <div class="ml-2 flex-shrink-0 flex">
                      <p v-if="result.reklamebeskyttet" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Reklamebeskyttet
                      </p>
                      <p v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Ikke reklamebeskyttet
                      </p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <!-- Heroicon name: solid/users -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 22 22" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                        </svg>
                        {{ result.cvrNummer }}
                      </p>
                      <p>
                        <a class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6 hover:text-indigo-600" target="_blank" :href="'https://www.google.com/maps/place/' + formatAddress(result.beliggenhedsadresse)">
                          <!-- Heroicon name: solid/location-marker -->
                          <svg class="flex-shrink-0 mr-1.5 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                          </svg>
                          {{
                            //prettier-ignore
                            formatAddress(result.beliggenhedsadresse)
                          }}
                        </a>
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <!-- Heroicon name: solid/calendar -->
                      <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                      </svg>
                      <p>
                        Closing on
                        <time datetime="2020-01-07">January 7, 2020</time>
                      </p>
                    </div>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </default-layout>
</template>

<script>
import { debounce } from "debounce";

import defaultLayout from "@/layouts/default.vue";
export default {
  components: {
    defaultLayout
  },
  data() {
    return {
      search: "",
      state: "",
      results: null,
      errors: null
    };
  },
  methods: {
    fieldUpdate() {
      if (this.search.length > 1) {
        this.state = "loading";
        this.debouncedUpdate();
      } else {
        this.state = "null";
        this.results = null;
      }
    },
    debouncedUpdate: debounce(function() {
      this.getCompany();
    }, 1000),
    getCompany() {
      this.$axios
        .get("/search/" + this.search + "?auth=cvrservice.webits.dev", {})
        .then(response => {
          this.results = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          // always executed
        });
    },
    formatAddress(AddressArr) {
      var fullAddress = "";

      if (AddressArr != null) {
        fullAddress += AddressArr[0].vejnavn;

        if (AddressArr[0].husnummerFra) {
          fullAddress += " " + AddressArr[0].husnummerFra;
        }
        if (AddressArr[0].bogstavFra) {
          fullAddress += AddressArr[0].bogstavFra;
        }
        if (AddressArr[0].husnummerTil) {
          fullAddress += "-" + AddressArr[0].husnummerTil;
        }
        if (AddressArr[0].bogstavTil) {
          fullAddress += AddressArr[0].bogstavTil;
        }
        if (AddressArr[0].etage) {
          fullAddress += ", " + AddressArr[0].etage;
        }
        if (AddressArr[0].sidedoer) {
          fullAddress += ". " + AddressArr[0].sidedoer;
        }
        if (AddressArr[0].postnummer) {
          fullAddress += ", " + AddressArr[0].postnummer;
        }
        if (AddressArr[0].postdistrikt) {
          fullAddress += " " + AddressArr[0].postdistrikt;
        }

        // "tekst": "Holger Danskes Vej 3A, st. tv, 2000 Frederiksberg",
        return fullAddress;
      } else {
        return "Adressebeskyttet eller ingen adresse";
      }
    }
  }
};
</script>
