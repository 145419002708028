import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.css";
import VueClickAway from "vue3-click-away";
import axios from "axios";
import { createHead } from "@vueuse/head";

const app = createApp(App);

const head = createHead();
app.use(head);
app.use(VueClickAway);
app.use(store);
app.use(router);
app.mount("#app");

const baseaxios = axios.create({
  baseURL: process.env.VUE_APP_API || "https://api.cvrservice.webits.dev",
  timeout: 3000,
  headers: { "Cache-Control": "no-cache" }
});

app.config.globalProperties.$axios = baseaxios;
