<template>
  <router-view />
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  setup() {
    useHead({
      script: {
        src: "https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js",
        "data-name": "BMC-Widget",
        "data-cfasync": "false",
        "data-id": "cvr.info",
        "data-description": "Support me on Buy me a coffee!",
        "data-message": "Køb os en kop kaffe",
        "data-color": "#5F7FFF",
        "data-position": "Right",
        "data-x_margin": "18",
        "data-y_margin": "18"
      }
    });
  }
};
</script>
